import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Center,
  VStack,
  chakra,
  Link,
  Icon,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Circle,
} from "@chakra-ui/react";

// Redux Imports
import { useDispatch } from "react-redux";

//Asset Imports
import logoblack from "@/public/images/nfluence/nfluence logo4.png";
import logopurple from "@/public/images/nfluence/nfluence logo5.png";

//NextJS Imports
import NextImage from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";

// Icon Imports
import { BsArrowUpLeft, BsArrowDownShort, BsArrowRight } from "react-icons/bs";
import Pulse from "react-reveal/Pulse";

// External Library Imports
import { motion, useTransform } from "framer-motion";
import Fade from "react-reveal/Fade";

const MotionBox = motion(Box);

const BorderBox = ({ canHover, mr }) => {
  return (
    <Box
      d="inline-flex"
      minW={["80px", "150px", "150px", "150px", "250px"]}
      height={"auto"}
      borderBottom={"3px solid #333"}
      transitionDuration={"1s"}
      mr={mr}
      ml="1.2vw"
    ></Box>
  );
};

const Home = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const content = [
    {
      text: [],
      image: "landing_bg.png",
    },
    {
      text: ["Creators", "brands", "middleman"],
      image: "NFLUENCE_LandingPage.png",
    },
    {
      text: ["Brands", "influencers", "labor to manage campaigns"],
      image: "landing_brandsbg.png",
    },
    {
      text: ["People", "People", "little details"],
      image: "landing_bg.png",
    },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currIndex, setCurrIndex] = useState(0);
  const [hasOpened, setHasOpened] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [newInterval, setNewInterval] = useState(60000);

  const checkRedirect = () => {
    if (currIndex === 0) {
      setCurrIndex(3);
      onClose();
      setNewInterval(30000);
    }
    if (currIndex == 3) {
      // router.push("/creators");
    }
  };

  useEffect(() => {
    if (hasOpened == false) {
      setTimeout(() => {
        onOpen();
        setHasOpened(true);
      }, 5000);
    }
  });

  useEffect(() => {
    // setTimeout(() => {
    //   if (currIndex === 0) {
    //     setCurrIndex(3);
    //   }
    // }, 5000);
    // setCurrIndex(3);
    const intervalID = setInterval(checkRedirect, newInterval); // 15 second shuffle for now
    return () => clearInterval(intervalID);
  }, [checkRedirect]);

  return (
    <Flex
      flexDirection={"column"}
      justify={"space-between"}
      minH="100vh"
      width="100%"
      height="100%"
      backgroundSize="cover"
      backgroundPosition={"center"}
      backgroundImage={
        `url('./images/nfluence/landing/` + content[currIndex]["image"] + `')`
      }
      zIndex={1}
      pt="15px"
      pl={["2em", "2em", "2em", "3em"]}
      overflowY={"scroll"}
    >
      <Flex flexDirection="column">
        <Flex paddingY={"15px"}>
          <Link
            cursor="pointer"
            onClick={() => {
              router.reload();
            }}
          >
            <Box position="relative" maxW={"200px"} maxH="40px">
              <NextImage
                src={currIndex == 0 || currIndex == 3 ? logopurple : logoblack}
              />
            </Box>
          </Link>
        </Flex>
        <VStack
          mt={["100px", "100px", "100px", "80px", "1em"]}
          align="flex-start"
          fontFamily="Poppins"
          fontSize="clamp(0.9rem, 5vw, 4.5rem);"
          ml={["0", "0", "0", "1em"]}
          color={
            currIndex == 0 || currIndex == 3 || currIndex === 1
              ? "white"
              : "black"
          }
          fontWeight={300}
        >
          <HStack flexWrap={"wrap"}>
            <Text>We help</Text>
            {currIndex !== 0 ? (
              <Box
                bg="inherit"
                fontFamily="Poppins"
                color={currIndex == 1 ? "creator_green" : "brand_purple2"}
                fontWeight="600"
              >
                <Fade spy={currIndex}>
                  <Center mx={["0", "0", "0", "0.5vw"]}>
                    {content[currIndex].text[0]}
                  </Center>
                </Fade>
              </Box>
            ) : (
              <Menu isOpen={isOpen} offset={[0, 0]} closeOnSelect={true}>
                <MenuButton
                  disabled={currIndex !== 0 ? true : false}
                  _disabled={{
                    _hover: { bg: "none", cursor: "text" },
                  }}
                  _expanded={{ bg: "inherit" }}
                  _active={{ bg: "inherit" }}
                  _selected={{ bg: "inherit" }}
                  _focus={{ bg: "inherit" }}
                  _hover={{ bg: "inherit" }}
                  _pressed={{ bg: "inherit" }}
                  _visited={{ bg: "inherit" }}
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                  display="inline-flex"
                  minW={["80px", "150px", "150px", "150px", "250px"]}
                  width={"auto"}
                  height={"auto"}
                  marginLeft={"15px"}
                  marginRight={"15px"}
                  borderBottom={currIndex !== 0 ? "0px" : "3px solid #333"}
                  transitionDuration={"1s"}
                  borderRadius="0px"
                  bg="#111"
                  fontFamily="Poppins"
                  fontSize="clamp(0.9rem, 5vw, 4.5rem);"
                >
                  <MotionBox
                    initial={{
                      backgroundColor: "#111",
                      opacity: 0,
                    }}
                    animate={{
                      // backgroundColor: "#666",
                      opacity: 1,
                    }}
                    transition={{
                      repeat: Infinity,
                      repeatType: "reverse",
                      repeatDelay: 0.8,
                      duration: 0.8,
                      delay: 0.5,
                      // ease: [0, 0.71, 0.2, 1.01],
                    }}
                  >
                    <Center color="brand_purple2">
                      <Icon as={BsArrowDownShort} boxSize={["8vw", "4vw"]} />
                    </Center>
                  </MotionBox>
                </MenuButton>
                <MenuList
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                  color="brand_purple2"
                  borderColor="inherit"
                  border="0px"
                  borderRadius={"0px"}
                  fontFamily="Poppins"
                  fontSize="clamp(0.875rem, 5vw, 4.5rem);"
                  bg="transparent"
                  minW="100%"
                >
                  <MenuItem
                    onClick={() => {
                      onClose();
                      setCurrIndex(1);
                    }}
                    _hover={{ bg: "inherit", textDecoration: "underline" }}
                  >
                    Creators
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onClose();
                      setCurrIndex(2);
                    }}
                    _hover={{ bg: "inherit", textDecoration: "underline" }}
                  >
                    Brands
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            <Text>work with</Text>
          </HStack>

          <HStack
            flexWrap={"wrap"}
            pt={
              currIndex == 0 && isOpen
                ? ["25vw", "20vw", "20vw", "20vw", "11vw"]
                : "10px"
            }
          >
            <Text>amazing</Text>
            <Fade clear spy={currIndex}>
              {currIndex == 0 ? (
                <BorderBox canHover={false} mr={"1.2vw"} />
              ) : (
                <Text
                  fontWeight={600}
                  color={currIndex == 1 ? "creator_green" : "brand_purple2"}
                  // pl="0.5vw"
                  // pr="0.5vw"
                  mx={["0", "0", "0", "0.5vw"]}
                >
                  {content[currIndex]["text"][1]}
                </Text>
              )}
            </Fade>
            <Text>without</Text>
          </HStack>

          <Flex
            flexWrap={"wrap"}
            lineHeight={["40px", "40px", "75px", "75px", "120px"]}
          >
            <Text>worrying about the</Text>

            <Fade clear spy={currIndex}>
              {currIndex == 0 ? (
                <Box>
                  <BorderBox canHover={false} mr="3px" />
                </Box>
              ) : (
                <Text
                  fontWeight={600}
                  color={currIndex == 1 ? "creator_green" : "brand_purple2"}
                  ml="1.2vw"
                >
                  {currIndex === 2
                    ? content[currIndex]["text"][2].substring(
                        0,
                        content[currIndex]["text"][2].indexOf(" ")
                      )
                    : content[currIndex]["text"][2]}
                </Text>
              )}
              {currIndex == 2 ? (
                <chakra.span fontWeight={600} color={"brand_purple2"}>
                  {content[currIndex]["text"][2].substring(
                    content[currIndex]["text"][2].indexOf(" ") + 1
                  )}
                </chakra.span>
              ) : (
                ""
              )}
            </Fade>
            <chakra.span fontFamily="Poppins">.</chakra.span>
          </Flex>
        </VStack>
      </Flex>
      <HStack w="100%" justifyContent={"flex-end"} pr="2em" mb="3em">
        {(currIndex == 0 || currIndex == 3) && (
          <NextLink href={"/creators"} passHref>
            <chakra.a zIndex={999}>
              <Circle
                color="brand_purple2"
                border="1px"
                borderColor="#444"
                mb="10px"
                onMouseOver={() => {
                  setIsHover(true);
                }}
                onMouseLeave={() => {
                  setIsHover(false);
                }}
                p="1em"
              >
                <Icon
                  as={BsArrowUpLeft}
                  boxSize="5vw"
                  minH="30px"
                  minW="30px"
                  maxH="80px"
                  maxW="80px"
                />
              </Circle>
            </chakra.a>
          </NextLink>
        )}
        {currIndex == 1 && (
          <Pulse when={isHover == true}>
            <NextLink href={"/creators"} passHref>
              <chakra.a
                onMouseOver={() => {
                  setIsHover(true);
                }}
                onMouseLeave={() => {
                  setIsHover(false);
                }}
              >
                <HStack spacing="1.5em">
                  <Text
                    fontFamily="Poppins"
                    fontSize="clamp(0.875rem, 4.5vw, 1.5rem);"
                    letterSpacing="-1px"
                    textTransform={"uppercase"}
                    fontWeight={700}
                    color="nfluence_green"
                  >
                    Learn How
                  </Text>
                  <Circle bg="nfluence_green" p="0.8em">
                    <Icon
                      as={BsArrowRight}
                      boxSize="5vw"
                      minH="30px"
                      minW="30px"
                      maxH="80px"
                      maxW="80px"
                    />
                  </Circle>
                </HStack>
              </chakra.a>
            </NextLink>
          </Pulse>
        )}
        {currIndex == 2 && (
          <Pulse when={isHover == true}>
            <NextLink href={"/brands"} passHref>
              <chakra.a
                onMouseOver={() => {
                  setIsHover(true);
                }}
                onMouseLeave={() => {
                  setIsHover(false);
                }}
              >
                <HStack spacing="1.5em">
                  <Text
                    fontFamily="Poppins"
                    fontSize="clamp(0.875rem, 4.5vw, 1.5rem);"
                    letterSpacing="-1px"
                    textTransform={"uppercase"}
                    fontWeight={700}
                    color="#000"
                  >
                    Learn How
                  </Text>
                  <Circle bg="nfluence_purple3" p="0.8em">
                    <Icon
                      as={BsArrowRight}
                      boxSize="5vw"
                      minH="30px"
                      minW="30px"
                      maxH="80px"
                      maxW="80px"
                    />
                  </Circle>
                </HStack>
              </chakra.a>
            </NextLink>
          </Pulse>
        )}
      </HStack>
    </Flex>
  );
};

export default Home;
